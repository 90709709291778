// StatusField.tsx
import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

const StatusField = ({ fieldName, falseText, TrueText }) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Typography variant="body2" color={record[fieldName] ? 'red' : 'green'}>
      {record[fieldName] ? TrueText : falseText}
    </Typography>
  );
};

export default StatusField;
